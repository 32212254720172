import { Movie, StreamingContent, WatchableContent } from "src/lib/movieinfo/movieinfotypes";

export function isMovie(t?: any): t is Movie {
    return t?.__typename === "Movie" || t?.__typename === "Movie";
}

export function isStreamingContent(t?: any): t is StreamingContent {
    return t?.__typename === "StreamingContent" || t?.__typename === "StreamingContent";
}

export function isWatchableContent(t?: any): t is WatchableContent {
    return t?.__typename === "WatchableContent" || t?.__typename === "WatchableContent";
}