import { Movie, StreamingContent, WatchableContent } from "src/lib/movieinfo/movieinfotypes";
import { isMovie, isStreamingContent, isWatchableContent } from "src/lib/movieinfo/typeGuards";
import { IFilmomtale, isFilmomtale } from "src/lib/types/Filmomtale";

//#region [Props]
type OptionalPosterInfoProps = {
    optionalDisplay?: "year";
    film: IFilmomtale | Movie | StreamingContent | WatchableContent;
    className?: string;
};
//#endregion

//#region [Component]
export default function OptionalPosterInfo({ optionalDisplay, film, className }: OptionalPosterInfoProps) {
    if (!optionalDisplay) return null;
    let optInfo: string | undefined | null;

    if (isStreamingContent(film)) {
        optInfo = film.year;
    } else if (isWatchableContent(film)) {
        optInfo = film.productionYear;
    } else if (isFilmomtale(film)) {
        optInfo = film.year?.toString();
    } else if (isMovie(film)) {
        optInfo = film.productionYear;
    }
    if (!optInfo) return null;
    return <div className={className}>{optInfo}</div>;
}
//#endregion
